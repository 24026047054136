import React from "react"
import Layout from "../components/layout"
import image from "../images/remote.png"
import image1 from "../images/inperson.png"

import { Link } from "gatsby"
import Footer from "../components/footer"

const InterviewFormat = () => {
  return (
    <>
      <Layout>
        <div className="rounded-b-2xl lg:rounded-b-3xl shadow-xl">
          <div className="">
            <div
              className="text-primary text-left"
              style={{ boxShadow: "0px 0px 15px 3px #0000001A" }}
            >
              <h1
                className="lg:display-1 text-large font-bold text-primary py-10 lg:mx-14 px-4 sm:px-6 "
                style={{ fontSize: "10vw", lineHeight: "100%" }}
              >
                Select your interview style{" "}
              </h1>
            </div>{" "}
            <div className="flex items-end justify-end pb-10 pt-40 ">
              <p
                className="text-large text-primary lg:mx-14 px-4 sm:px-6 "
                style={{ fontSize: "8vw", lineHeight: "74%" }}
              >
                Online
              </p>
            </div>
            <div className="flex items-center justify-center md:py-20 sm:py-5 flex-wrap lg:mx-14 px-4 sm:px-6 ">
              <div className="flex-auto md:w-44 sm:w-100 md:pr-48 pb-6">
                <h2 className="bold text-lg font-bold w-60">
                  What is the online interview experience?
                </h2>
                <p className="pt-6">
                  Online interviews are approximately 90 minutes long, conducted
                  over Zoom. Your loved one will sit in a location with good
                  lighting and use a microphone (or headphones) that will ensure
                  quality sound. <br />
                  <br /> The interview questions will proceed similarly to the
                  in-person interviews with the host, Vance Crowe, guiding the
                  conversation through the 5 topic areas: childhood, career,
                  marriage, parenting and legacy.
                </p>
                <div className="mt-10">
                  <a
                    href="https://buy.stripe.com/28o3cdbDl5OS8WA3cg"
                    className="px-8 py-2 space-x-2 text-white rounded-full hover:bg-white hover:text-navbar border-2 border-navbar bg-navbar "
                    style={{ boxShadow: "0px 2px 5px 3px rgba(0, 0, 0, 0.25)" }}
                    target="_blank"
                  >
                    <span>Book an Online Interview</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline-block -rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div
                className="relative flex-auto w-32 sm:mt-6 sm:mb-40 xs:mb-40"
                style={{ height: "400px" }}
              >
                <img
                  src={image}
                  alt={`Hero image`}
                  className="absolute inset-0 w-full h-full object-none object-cover rounded-xl"
                  style={{ height: "100%" }}
                />
                <div
                  class=" relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg"
                  style={{ height: "100%" }}
                >
                  {" "}
                  <div class="flex gap-4"></div>
                  <div>
                    <p class="font-semibold">
                      {"What is the online interview experience?"}
                    </p>
                    <span class="">
                      {
                        "A 90 minute video interview conducted over Zoom between your loved one and Vance Crowe."
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-left pb-10 pt-20 sm:pt-20">
              <p
                className="text-large text-primary lg:mx-14 px-4 sm:px-6 "
                style={{ fontSize: "8vw", lineHeight: "74%" }}
              >
                In-Person
              </p>
            </div>
            <div className="flex items-center justify-center md:py-20 sm:py-5 flex-wrap lg:mx-14 px-4 sm:px-6 ">
              {" "}
              <div
                className="relative flex-auto w-32 sm:mt-6"
                style={{ height: "400px" }}
              >
                <img
                  src={image1}
                  alt={`Hero image`}
                  className="absolute inset-0 w-full h-full object-center object-cover rounded-xl "
                  style={{ height: "100%" }}
                />
                <div
                  class=" relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg"
                  style={{ height: "100%" }}
                >
                  {" "}
                  <div class="flex gap-4"></div>
                  <div>
                    <p class="font-semibold">
                      {"What is the in-person interview experience?"}
                    </p>
                    <span class="">
                      {
                        "The in-person interview experience will take place with the host in a state of the art recording studio."
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-auto md:w-44 sm:w-100 md:pl-48 pb-6 pt-5">
                <h2 className="bold text-lg font-bold w-60">
                  What is the in-person interview experience?
                </h2>
                <p className="pt-6">
                  The in-person interview experience will be a high quality,
                  fulfilling experience. Your loved one and a guest will visit a
                  state of the art recording studio, be offered drinks and
                  comfortable seating, and sit down with the highly personable
                  host Vance Crowe for a discussion that is focused on
                  themselves. Interviewees often remark that the experience was
                  so enjoyable that it seemed like it flew by. <br /> <br /> The
                  interview will last approximately 2 hours. <br /> <br />
                  If you are the accompanying guest, you will be able to view
                  the interview through a studio window and listen via
                  headphones to the conversation. Guests tell us, it is like
                  watching the most amazing movie they have ever seen.
                </p>
                <div className="mt-10">
                  <a
                    href="https://buy.stripe.com/14k001ePx4KOc8MaEH"
                    className="px-8 py-2 space-x-2 text-white rounded-full hover:bg-white hover:text-navbar border-2 border-navbar bg-navbar "
                    style={{ boxShadow: "0px 2px 5px 3px rgba(0, 0, 0, 0.25)" }}
                    target="_blank"
                  >
                    <span>Book an In-Person Interview</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline-block -rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <Footer />
      </Layout>
    </>
  )
}

export default InterviewFormat
